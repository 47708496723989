.password-reset-section {
  padding: $padding-mobile 0;
}

.password-reset {

  .col-content {
    padding: $padding-mobile;
    background-color: $box-bg;
  }
  fieldset {
    padding: $fieldset-padding-top 0 $fieldset-padding-bottom;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: $tablet-min){
  .password-reset-section {
    padding: $padding-tablet 0;
  }
  .password-reset {
    .col-content {
      padding: $padding-tablet;
    }
  }
}

@media (min-width: $desktop-min){
  .password-reset-section {
    padding: $padding-tablet 0 $padding-tablet * 2;
  }
  .password-reset-header {
    .section-header {
      &.h2 {
        margin-top: -3rem;
      }
    }
  }
}