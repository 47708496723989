$font-1: 'Lato', sans-serif;
$font-2: 'Roboto', sans-serif;

$body-color: #7d7d7d;

$padding-mobile: 2rem;
$padding-tablet: $padding-mobile * 2;
$padding-desktop: 5rem;
$padding-lg: 6rem;

$mobile-sm: 420px;
$mobile-max: 599px;
$tablet-min: 600px;
$tablet-max: 1024px;
$desktop-min: 1025px;
$desktop-md: 1280px;
$desktop-lg: 1400px;

$accent: #e7305e;
$accent-dark: #81217d;

$menu-color: #7d7d7d;
$menu-color-light: #a7a7a7;

$form-border: #eeeeee;
$form-label: $menu-color;
$form-input: $menu-color-light;
$form-disabled: #dcdcdc;
$fieldset-width: 50rem;
$fieldset-padding-top: 0.35em;
$fieldset-padding-bottom: 0.75em;
$fieldset-padding: $fieldset-padding-top 0.625em $fieldset-padding-bottom;
$default-button-width: 240px;

$brand-img-width: 106px;
$menu-height-mobile: 60px;
$menu-height-desktop: 130px;
$breadcrumb-link: #a7a7a7;
$box-bg: #f6f6f6;

$header-color: #36454f;
$h3-color: $menu-color;
$h4-color: $accent-dark;

// Steps
$step-complete-title: #00784c;
$step-complete-icon: #3a9e00;
$step-complete-frame: #70e10f;
$step-complete-description: #399d00;
$step-active-title: $header-color;
$step-active-icon: $accent-dark;
$step-active-frame: $accent;
$step-active-description: $menu-color;
$step-disabled-title: #d1d1d1;
$step-disabled-icon: #d1d1d1;
$step-disabled-frame: $box-bg;
$error: #db4437;
$success: #4caf50;

// Icons
$fill-light: #fff;
$fill-dark: $menu-color-light;
$icon-size: 18px;
$transition-length: .15s;

// Home page
$gradient-top: $accent;
$gradient-bottom: $accent-dark;
$font-size-sm: 1.2rem;

// Boxes
$box-mobile-height: 300px;
$box-tablet-height: 200px;
$box-desktop-height: 450px;
