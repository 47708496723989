.section-academy {
    +section {
        padding-top: 1rem;
    }
}

.box-video {
    .col {
        min-height: auto;
    }
}

.col-video {
    @extend %frame;
    margin-top: $padding-mobile;
}

.video {
    width: 100%;
    height: auto;
    position: relative;
    &.disabled {
        display: none;
    }
}

.video-progress {
    @extend .presentation-progress;
    max-width: 100%;
    cursor: pointer;
    position: absolute;
    bottom: -2px;
    left: 11rem;
    right: 0;
    width: auto;
    .progress-bar {
        height: 1rem;
    }

    @media (max-width: $mobile-max) {
        left: 7.5rem;
    }
}

%video-controls {
    @extend %align-h;
    @extend %sticker-lg;
    bottom: -4.5rem;
    left: 6rem;
    cursor: pointer;
    z-index: 5;

    @media (max-width: $mobile-max) {
        height: 7rem;
        width: 7rem;
        border-width: 6px;
        bottom: -3.5rem;
        left: 4rem;
    }
}

.video-play {
    @extend %video-controls;
}

.video-pause {
    @extend %video-controls;
    display: none;
}

.video-replay {
    @extend %video-controls;
    display: none;
    .videos-complete & {
        display: block;
    }
}

.video-fullscreen {
    position: absolute;
    bottom: $padding-mobile;
    right: $padding-mobile;
    cursor: pointer;
    z-index: 5;
    .icon {
        height: 3rem;
        width: 3rem;
    }

    @media (max-width: $mobile-max) {
        right: 1rem;
        bottom: 1rem;
    }
}

.next-video-intro {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    background-color: $box-bg;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .h2 {
        font-size: 2rem;
    }
    .show-next-video-intro & {
        display: flex;
    }
}

.show-next-video-intro {
    .video-control {
        display: none;
    }
}

.video-playing {
    .video-play {
        display: none;
    }
    .video-pause {
        display: block;
    }
    &.show-next-video-intro {
        .video-control {
            display: none;
        }
    }
}

@media (max-width: $tablet-max) {
    .col-video {
        margin-bottom: 4.5rem;
    }
}

@media (min-width: $tablet-min) {
    .col-video {
        margin-top: $padding-tablet;
    }
}

@media (min-width: $desktop-min) and (max-width: $desktop-lg) {
    .box-video {
        .col-video {
            margin-left: 0;
            margin-right: 2rem;
            margin-bottom: 6rem;
        }
    }
}

@media (min-width: $desktop-min) {
    .box-video {

        // .col-video {
        //     margin-left: $padding-desktop;
        // }
        .col {
            width: 60%;
        }

        .col-content {
            width: 40%;
        }
    }

    .box-intro {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            height: 100%;
            width: 100%;
            transform: translateY(-50%);
            background-repeat: no-repeat;
            background-position: 100% 70%;
            pointer-events: none;
        }
    }

    .box-academy-step-2 {

        .col-content {
            width: 65%;

            h3 {
                margin-right: 15%;
            }
        }

        &:after {
            background-size: auto 50%;
            background-image: url("../images/product-1.png");
        }
    }

    .box-academy-step-3 {
        .col-content {
            min-height: 380px;
        }
        &:after {
            background-image: url("../images/step-3-logos.png");
            background-position: 80% 70%;
        }
        h2 {
            padding-right: 10%;
        }
    }
}

@media (min-width: $desktop-md) {

    .col-video {
        margin-left: $padding-desktop;
    }


    .col-video {
        margin-right: -5rem;
        margin-bottom: -10rem;
        margin-top: 5rem;
    }

    .box-academy-step-2 {

        &:after {
            right: -7rem;
            background-size: auto 60%;
        }
    }
}