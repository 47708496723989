.main-menu {
    font-family: $font-2;
    transform: translateX(0);
    text-transform: uppercase;
    font-weight: 900;
    font-size: $font-size-sm;
    color: $menu-color;

    .menu {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ul {
        @extend %menu-list;
    }

    li {
        @extend %underline;
    }

    a {
        color: $menu-color;
    }

    .active {
        >a {
            color: $accent;
        }
    }

    .menu-open & {
        transform: translateX(0);
    }

    .dropdown {
        color: $menu-color-light;

        a {
            color: $menu-color-light;
            display: block;
        }
    }
}

.nav-btn {
    @extend %btn-base;
    position: absolute;
    top: $padding-mobile;
    right: $padding-mobile;
    z-index: 101;
    border: 0 none;
    padding: 0;
    margin: 0;
    height: 2.4rem;
    width: 3rem;
    cursor: pointer;

    span {
        display: block;
        height: 0;
        width: 100%;
        border-bottom: 4px solid rgba($accent, .9);
        position: absolute;
        left: 0;
        transform-origin: 0% 50%;
        transition: .3s ease;

        &:first-child {
            top: 0;

            .menu-open & {
                transform: rotate(45deg);
            }
        }

        &:nth-child(2) {
            top: 10px;

            .menu-open & {
                transform: scale(0);
            }
        }

        &:last-child {
            top: 20px;

            .menu-open & {
                top: 21px;
                transform: rotate(-45deg);
            }
        }
    }
}

.brand-img {
    height: 40px;
    width: $brand-img-width;
    @extend %align-v;
}

.dropdown {
    position: relative;

    ul {
        margin-top: 0;
        flex-direction: column;
        width: 100%;
        text-align: left;
        transform: scaleY(0);
        transition: transform $transition-length linear;
        transform-origin: 0 0;
        border-radius: 8px;

        li {
            display: block;
            width: 100%; //padding: .5rem 0;
        }
    }

    .icon-dropdown {
        transition: transform .15s linear;
        transform-origin: 50% 50%;
    }

    .menu-dropdown {
        visibility: hidden;
        position: absolute;
        clip: rect(1px, 1px, 1px, 1px);
        width: 0;
        height: 0;
    }

    &.dropdown-open {
        ul {
            transform: scaleY(1);
        }

        label {
            color: $accent;

            .icon {
                fill: $accent;
            }

            .icon-dropdown {
                transform: rotate(-180deg);
            }
        }
    }

    .icon-gear {
        margin-right: 1rem;
    }
}


@media (max-width: $tablet-max) {
    .main-menu {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: transform .3s linear;
        transform: translateX($tablet-max);
        overflow-y: auto;
        font-size: 2rem;

        .menu {
            flex-direction: column;
            background-color: rgba(#000, .85);
            padding: $padding-mobile;
            width: 100%;
            min-height: 100%;
        }

        li {
            text-align: center;
            width: 100%;
        }

        a {
            display: block;
            color: $fill-light;
            padding: .5rem 0;
        }
    }
}

@media (min-width: $tablet-min) {
    .nav-btn {
        right: $padding-tablet;
    }
}

@media (min-width: $desktop-min) {
    .main-menu {
        transform: translateX(0);
        margin-left: $brand-img-width + 40px;

        .menu {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
        }



        ul {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        a {
            padding: 1rem 2rem;
        }
    }


    .nav-btn {
        display: none;
    }

    .dropdown {
        position: absolute;
        top: -1rem;
        right: 0;
        border-radius: 5px;
        transition: box-shadow 0.15s linear;

        label {
            padding: 1rem 2rem;
            display: block;
            cursor: pointer;
        }

        ul {
            background-color: #fff;
        }

        &.dropdown-open {
            box-shadow: 0 0 10px rgba(#000, .15);

            ul {
                transform: scaleY(1);
            }
        }
    }
}