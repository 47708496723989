._404 {
  .col-content {
    padding: 6.5rem 5rem;
  }

  .col-image {
    margin: -1rem 0 -8rem;
  }

  .box-dark-left {
    &:before {
      margin-bottom: 6rem;
    }
  }

  .h3 {
    font-weight: normal;
    margin-bottom: 2.5em;
  }

  .p {
    line-height: 3rem;
  }

  @media (min-width: $desktop-min){
    .col-image {
      .image-404 {
        background-position: 35%;
      }
    }
  }
}