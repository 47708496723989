%btn-base {
    -webkit-appearance: none;
    background-color: transparent;
    border-radius: 0;
    &:active,
    &:focus,
    &:hover {
        box-shadow: none;
        outline: 0;
    }
}

.btn {
    @extend %btn-base;
    border: 2px solid;
    padding: 1.5rem 2rem;
    min-width: 200px;
    border-radius: 3px;
    font-family: $font-2;
    font-size: $font-size-sm;
    font-weight: 900;
    text-transform: uppercase;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    outline: 0;
    box-shadow: none;
    white-space: nowrap;
    display: inline-block;
    line-height: 1;
    margin-top: 1rem;
    &:active,
    &:focus,
    &:hover {
        border-color: $accent;
        color: $accent;
    }
}

.btn-sm {
    min-width: 120px;
    padding: 1rem 2rem;
}

.btn-accent {
    @include button($accent, #fff, $accent-dark, #fff); // $bg, $color, $hover-bg, $hover-color
    &:active,
    &:focus,
    &:hover {
        background-color: $accent-dark;
        border-color: $accent-dark;
        color: #fff;
        box-shadow: 0px 2px 20px 0px rgba($accent, .15);
    }
}

.btn-light {
    @include button(#fff, $accent, $accent, #fff);
}

.btn-disabled {
    @include button($form-disabled, #fff, $form-disabled, #fff);
    cursor: default;
}

[type="button"],
[type="reset"],
[type="submit"] {
    @extend %btn-base;
}

input {
    &:focus {
        outline: 0;
    }
}

.form-row {
    margin: 1rem 0;
}

.form-input {
    border: 1px solid $form-border;
    border-radius: 3px;
    box-shadow: none;
    outline: 0;
    padding: 1rem 2rem;
    width: 100%;
}

.form-label {
    display: block;
    font-weight: 700;
}

.form-description {
    font-size: 1rem;
    line-height: 1.6;
}

.form-required {
    color: $error;
    font-weight: bold;
}

.checkbox-styled {
    margin-top: 3rem;
    margin-bottom: 3rem;

    input[type=checkbox],
    input[type=radio] {
        position: absolute;
        clip: rect(1px, 1px, 1px, 1px);
        width: 0;
        height: 0;
    }

    input[type=checkbox] {
        &:checked {
            +label {
                &:before {
                    background-image: url('../images/icons/ico-check.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 12px auto;
                    border-color: $accent;
                    background-color: $accent;
                }
            }
        }
    }

    input[type=radio] {
        &:checked {
            +label {
                &:before {
                    border-color: $accent;
                    background-color: $accent;
                    border-width: 2px;
                    box-shadow: inset 0 0 0 3px #fff;
                }
            }
        }

        +label {
            &:before {
                border-radius: 50%;
                box-shadow: inset 0 0 0 3px #fff;
            }
        }
    }

    label {

        cursor: pointer;
        font-size: 1.4rem;
        line-height: 1.4;
        display: block;
        width: 100%;
        position: relative;
        padding-left: 4rem;

        &.label-el {
            position: static;
        }

        &:before {
            content: "";
            display: inline-block;
            width: 1.8rem;
            height: 1.8rem;
            border: 1px solid darken($box-bg, 15%);
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0; //box-shadow: inset 0 0 0 2px #fff;
        }
    }

    &.form-error {
        label {
            &:before {
                border-color: $error;
            }
        }

        +.parsley-errors-list {
            margin-top: -2rem;
        }
    }
}

.parsley-errors-list {
    @extend %error-list;
}

.register-form, .settings-form {
    ul {
        @extend %error-list;
    }
}

.login-section {
    margin-bottom: $padding-mobile;

    .container {
        max-width: 400px;
    }

    h1 {
        margin-top: .5em;
    }

    .form-links {
        font-size: $font-size-sm;
        font-weight: 700;
        padding: 2rem 0;
    }

    a {
        text-decoration: underline;
    }
}

.errorMessage {
    color: $error;
    line-height: 1.4;
    font-size: 1.4rem;
}

@media (min-width: $tablet-min) {
    .register-form, .settings-form {
        ul {
            padding-left: 6rem;
            padding-bottom: 1rem;
        }
    }

    .login-section {
        margin-bottom: $padding-tablet;
    }
}

.form-container {
    .form-submit {
        width: $default-button-width;
        margin: auto;
        color: white;
    }
    fieldset, .fieldset {
        padding: $fieldset-padding;
        border: 0;
        width: 100%;
        margin: auto;
    }
    label, input {
        display: block;
        font-size: 1.4rem;
    }
    input {
        box-shadow: none;
        outline: 0;
        appearance: none;
        height: 5rem;
        width: 100%;
        color: $form-input;
        border: 0.1rem solid $form-border;
        border-radius: 0.4rem;
        padding: 1.5rem 3rem;
    }
    label {
        line-height: 5rem;
        color: $form-label;
        font-weight: bold;
    }
    @media (min-width: $tablet-min) {
        fieldset, .fieldset {
            position: relative;
            width: $fieldset-width;
        }

        $label-width: 13rem;
        $label-left: 4rem;


        padding: $padding-tablet;
        fieldset, .fieldset {
            width: $fieldset-width;
            max-width: calc( 100% - #{$label-width})
        }
        label {
            left: -1 * $label-width + $label-left;
            width: $label-width;
        }
    }
}

.settings, .registration {
    background-color: $box-bg;
    padding: 0.5rem;

    .checkbox-styled {
        margin-top: 1em;
    }
    .checkbox-styled label {
        position: relative;
        text-align: left;
    }

    @media (min-width: $mobile-sm) {
        padding: $padding-mobile;
    }

    @media (min-width: $tablet-min) {
        input {
            margin-left: 6rem;
        }
        .checkbox-styled label, .info-fieldset {
            left: 6rem;
        }
        label {
            text-align: right;
            position: absolute;
        }
    }
}

@media (max-width: $tablet-max) {
    .form-row {
        margin: 1rem 0 2rem;
    }
}

@media (min-width: $tablet-min) {
    .form-row {
        display: flex;
        justify-content: space-between;
        .form-label {
            flex: 1;
        }
        .form-input {
            flex: 2;
        }
    }
    .form-label {
        display: inline-block;
    }
    .form-input {
        width: auto;
    }
}

@media (min-width: $desktop-min) {
    .btn {
        min-width: 240px;
        padding: 2rem 4rem;
    }

    .login-section {
        margin-bottom: $padding-desktop;
    }
}