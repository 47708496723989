html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    font-family: $font-1;
    color: $body-color;
    line-height: 1.8;
    padding-top: $menu-height-mobile;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 900;
}

h1,
h2,
.h1,
.h2 {
    font-family: $font-2;
    color: $header-color;
    font-size: 3rem;
    margin: 1em 0 0.3em 0;
    line-height: 1;
}

h1,
.h1 {}

h2,
.h2 {
    color: $header-color;
}

h3,
.h3 {
    font-size: 1.6rem;
    color: $h3-color;
    line-height: 1.8;
}

h4,
.h4 {
    font-size: 1.6rem;
    color: $h4-color;
}

h5,
.h5,
h6,
.h6 {
    font-size: 1.6rem;
    color: $h3-color;
}

a {
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: color $transition-length linear;
    color: $accent;
    &:hover,
    &:active,
    &:focus {
        outline: 0;
    }
    .active & {
        color: $accent;
    }
}

strong {
    color: $accent;
}


article {
    ul:not(.parsley-error-list) {

        list-style: none;
        margin: $padding-mobile 0;
        padding: 0;

        li {
            position: relative;
            padding-left: $padding-mobile;

            &:before {
                content: '';
                width: 4px;
                height: 4px;
                position: absolute;
                top: 10px;
                left: 0;
                border-radius: 50%;
                border: 2px solid $accent;
            }

            &:not(last-child) {
                margin-bottom: $padding-mobile;
            }
        }
    }
}

ol {
    list-style-position: inside;
    padding: 0;

    li {
        &:not(last-child) {
            margin-bottom: .5rem;
        }
    }
}

.list-bullets {
    @extend %list-bullets;
}

.section-header {
    padding-left: $padding-mobile;
}

@media (min-width: $tablet-min) {
    h1,
    .h1 {
        font-size: 5rem;
    }

    h2,
    .h2 {
        font-size: 5rem;
    }

    h3,
    .h3,
    h4,
    .h4 {
        font-size: 1.8rem;
    }

    h5,
    .h5,
    h6,
    .h6 {
        font-size: 1.6rem;
    }

    .section-header {
        padding-left: $padding-tablet;
    }
}

@media (min-width: $desktop-min) {

    h1,
    .h1 {
        font-size: 6rem;
        margin-top: -3rem;
    }
}

@media (min-width: $desktop-lg) {
    body {
        padding-top: $menu-height-desktop;
    }

    h1,
    .h1 {
        font-size: 8rem;
        margin-top: -4rem;
    }
    h2,
    .h2 {
        font-size: 6rem;
    }
    .section-header {
        padding-left: $padding-desktop;
    }
}