.breadcrumbs {
	font-family: $font-2;
	font-size: $font-size-sm;
	text-transform: uppercase;
	font-weight: 900;
	background-color: $box-bg;
	height: 80px;
	.container {
		height: 100%;
	}
	a {
		color: $breadcrumb-link;
		&:hover {
			color: $accent;
		}
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	li {
		padding: 0 1rem;
		position: relative;
		&:not(last-child) {
			padding-right: 3rem;
			margin-right: .5rem;
		}
	}
	.active {
		color: $accent;
	}
}

.breadcrumbs-menu {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	height: 100%;
}

@media (min-width: $tablet-min) {
	.breadcrumbs-menu {
		// display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-direction: row; // height: 100%;
	} // align-items: flex-start;
	// justify-content: center;
	// height: 100%;
	// flex-direction: column;
}
