.home-main-section {
    position: relative;
}

.home-main-article {
    background-image: linear-gradient(to bottom, rgba(218, 46, 98, 1) 0%, rgba(143, 35, 121, 1) 100%);
    position: relative;
    padding: $padding-mobile;
    color: #fff;
    h1 {
        color: inherit;
        margin: 0.3em 0;
    }
}

.scroll-btn {
    &:not(.btn) {
        @extend %sticker;
        position: absolute;
        bottom: 6rem;
        right: 4rem;
        box-shadow: 0 2px 6px rgba(#000, .2);
        transition: background-color $transition-length linear;
        &:hover {
            background-color: $accent-dark;
        }
    }
}

@media (max-width: $tablet-max) {
    .scroll-btn {
        display: none;
    }
}

@media (min-width: $tablet-min) and (max-width: 992px) {
    .home-main-section {
        .container {
            padding: 0;
        }
    }
    .home-main-article {
        padding: $padding-tablet;
    }
}

@media (min-width: 992px) {
    .home-main-article {
        width: 50%;
        padding: $padding-tablet $padding-tablet $padding-tablet 0;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 100%;
            bottom: 0;
            left: -100%;
            background-image: linear-gradient(to bottom, rgba(218, 46, 98, 1) 0%, rgba(143, 35, 121, 1) 100%);
        }
    }
    .home-image-placeholder {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 4rem;
        left: 0;
        background-image: url('../images/home.jpg');
        background-size: cover;
        background-position: right center;
        background-repeat: no-repeat;
    }
}

@media (min-width: $desktop-min) {
    .home-main-article {
        width: 40%;
        padding: $padding-desktop $padding-desktop $padding-desktop 0;
    }
}