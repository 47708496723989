@mixin button($bg, $color, $hover-bg, $hover-color) {
	color: $color;
	background-color: $bg;
	border-color: $bg;
	box-shadow: 0px 5px 20px 0px rgba($bg, .25);
	transition: all $transition-length linear;
	.icon {
		transition: fill $transition-length linear;
		fill: $color;
	}
	&:active,
	&:focus,
	&:hover {
		background-color: $hover-bg;
		border-color: $hover-bg;
		color: $hover-color;
		box-shadow: 0px 2px 20px 0px rgba($hover-bg, .15);
		.icon {
			fill: $hover-color;
		}
	}
}
