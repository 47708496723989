.presentation-container {
    position: relative;
    background-color: $box-bg;
    text-align: center;
}

.presentation {
    width: auto;
    margin: $padding-mobile $padding-mobile 0; //border: 1px solid;
}

.presentation-controls {
    display: flex;
    justify-content: space-between;
    padding: $padding-mobile;
}

.presentation-progress {
    @extend %heading;
    width: 700px;
    max-width: 70%;
    text-align: left;

    .progress-bar {
        height: 5px;
        width: 100%;
        background-color: $form-disabled;
    }

    .progress-bar-active {
        background-color: $accent;
        height: 100%;
        width: 0;
        transition: width .15s linear;
    }
}

.presentation-slide-count {
    @extend %counter-box;
    min-width: 200px;
}

.presentation-nav {
    display: flex;
    align-items: center;
    justify-content: center;
}

.owl-prev, .owl-next {
    cursor: pointer;
    width: 7rem;

    .icon {
        fill: $accent;
        width: 17px;
        height: 28px;
    }

    &.disabled {
        .icon {
            fill: $form-disabled;
        }
    }
}

.presentation-finished {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        margin: 0;
    }

    .btn-accent {
        color: #fff;
    }
}

@media (min-width: $tablet-min) {
    .presentation {
        margin: $padding-tablet $padding-tablet 0;
    }

    .presentation-slide-count {
        min-width: 250px;
    }

    .presentation-controls {
        padding: $padding-mobile $padding-tablet;
    }
}

@media (min-width: $desktop-min) {
    .presentation {
        margin: $padding-desktop $padding-desktop 0;
    }

    .presentation-slide-count {
        position: absolute;
        top: -$padding-mobile;
        left: -$padding-mobile;
    }

    .presentation-controls {
        padding: $padding-tablet $padding-desktop $padding-desktop;
    }
}

@media (min-width: $desktop-min) and (max-width: $desktop-lg) {
    .presentation, .presentation-controls {
        margin-left: 330px;
    }

    .presentation-controls {
        padding: $padding-tablet $padding-mobile $padding-tablet 0;
    }

    .presentation-slide-count {
        top: 0;
        left: $padding-tablet;
    }
}

@media (min-width: $desktop-lg) {

    .presentation-slide-count {
        position: absolute;
        top: -$padding-mobile;
        left: -$padding-mobile;
    }

    .col-video {
        margin-right: -5rem;
        margin-bottom: -10rem;
        margin-top: 5rem;
    }

    .box-academy-step-2 {

        &:after {
            right: -7rem;
            background-size: auto 60%;
        }
    }

    .presentation {
        width: 100%;
        margin: $padding-desktop $padding-desktop $padding-tablet;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 2rem;
            left: 2rem;
            border: 1rem solid $breadcrumb-link;
            background-color: $box-bg;
        }
    }

    .presentation-slide-count {
        top: -$padding-tablet;
        left: -$padding-tablet;
    }

    .presentation-controls {
        padding: $padding-mobile $padding-desktop $padding-desktop;
    }
}