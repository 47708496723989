.main-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 101;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(#000, 0.3);

    .container {
        position: relative;
    }
}

@media (max-width: $tablet-max) {
    .main-header {
        .container {
            min-height: $menu-height-mobile;
        }
    }
}

@media (min-width: $desktop-min) and (max-width: $desktop-lg) {
    .main-header {
        height: $menu-height-mobile;
        display: flex;
        align-items: center;

        .menu {
            span {
                &::after {
                    bottom: -5px;
                }
            }
        }
    }
}

@media (min-width: $desktop-lg) {
    .main-header {
        height: $menu-height-desktop;
        display: flex;
        align-items: center;
        transition: height $transition-length linear;

        .menu-scrolled & {
            height: $menu-height-mobile;

            .menu {
                span {
                    &::after {
                        bottom: -5px;
                    }
                }
            }
        }
    }
}