.section-quiz {
    .nano {
        height: 600px;
    }

    .box-quiz {
        .col {
            width: 100%;
        }
    }
}

.form-quiz {
    padding: $padding-mobile 0 0;
    .btn {
        display: block;
        margin-top: $padding-mobile;
        margin-right: auto;
        margin-left: auto;
    }
}

.quiz-item {
    margin-bottom: $padding-desktop;
}

.quiz-question {
    @extend %heading;
    display: flex;
    line-height: 1.4;
    margin-bottom: 0;
}

.counter {
    @extend %counter;
}

.quiz-timer {
    text-align: center;
    position: relative;
    z-index: 5;

    .box-dark &, .box-dark-left &, .box-dark-right & {
        background-color: $box-bg;
    }

    .timer {
        @extend %counter-box;

        p {
            margin: 0 0 0 $padding-mobile;
        }

        .icon {
            width: 36px;
            height: 36px;
        }
    }
}

.quiz-score-list {
    padding: 0;
    margin: 0 0 $padding-mobile;

    +.btn {
        margin-top: 0;
    }
}

.quiz-score-item {
    @extend %heading;
    font-size: $padding-mobile;
    display: flex;
    align-items: center;
    margin-top: $padding-mobile;
    line-height: 1.2;
    white-space: nowrap;

    .icon {
        margin-right: $padding-mobile;
        width: 36px;
        height: auto;
        max-height: 36px;
    }
}

.box-quiz-result {
    .h3 {
        margin-bottom: 0;
    }

    .nano {
        height: auto;

        .nano-pane {
            bottom: 0;
        }

        .nano-content {
            margin-top: 2rem;
            bottom: 0;
        }
    }
}

.quiz-results-table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    padding-bottom: 6rem;

    thead {
        @extend %heading;
        position: sticky;
        top: 0;
        background-color: #fff;
    }

    tr {
        border-bottom: 1px solid $form-border;
    }

    th {
        padding: 2rem 0;
    }

    td {
        padding: 1.5rem 0;
    }
}

.quiz-nav {
    display: none;
}

.quiz-nav-prev, .quiz-nav-next {
    position: absolute;
    bottom: $padding-desktop;
    cursor: pointer;

    .icon {
        transition: fill .2s linear;
    }

    span {
        display: block;
        color: $accent;
        transition: color .2s linear;
        line-height: 1.4;
    }

    &:hover {
        .icon {
            fill: $accent-dark;
        }

        span {
            color: $accent-dark;
        }
    }
}

.quiz-nav-prev {
    left: $padding-desktop;
    display: none;
}

.quiz-nav-next {
    right: $padding-desktop;
}

@media (max-width: $tablet-max) {

    .box-quiz-result {
        .nano {
            min-height: 500px;
            .nano-content {
                padding: 0
            }

            .nano-pane {
                right: 0;
            }
        }
    }

    .quiz-results-table {
        width: calc(100% - 1rem);

        .counter {
            padding-right: 0.5rem;
        }
    }
}

@media (min-width: $tablet-min) {

    .quiz-timer {
        text-align: right;

        .timer {
            margin-top: -$padding-tablet;
            margin-right: $padding-tablet;
        }
    }

    .quiz-score-list {
        margin: 0 0 $padding-tablet;
    }

    .quiz-score-item {
        font-size: 3rem;
        margin-top: $padding-tablet;

        .icon {
            width: 60px;
            max-height: 60px;
        }
    }

    .quiz-results-table {

        th {
            padding-top: 0;
        }
        .counter {
            padding-right: 2rem;
        }
    }
}

@media (max-width: $tablet-max) {
    .quiz-item {
        display: block !important;
        padding-right: 2rem;
    }
}

@media (min-width: $desktop-min) {

    .quiz-timer {
        padding-bottom: 2rem;
        .box-dark-left &, .box-dark-right & {
            max-width: 50%;
        }

        .timer {
            margin-top: -7rem;
            margin-right: -$padding-desktop;

            .icon {
                width: 55px;
                height: 55px;
            }
        }
    }

    .quiz-score-list {
        margin: 0 0 $padding-desktop;
    }

    .quiz-score-item {
        margin-top: $padding-desktop;
    }

    .box-quiz-result {
        .nano {
            margin-top: -5rem;
        }
    }

    .section-quiz {
        .nano {
            height: auto;

            .nano-content {
                position: static;

                .btn {
                    display: none;
                }
            }
        }

        .quiz-timer {
            .timer {
                margin-top: -7rem;
                margin-right: -$padding-mobile;
            }
        }
    }

    .quiz-nav {
        display: block;
    }
}

@media (min-width: $desktop-lg) {
    .section-quiz {
        .quiz-timer {
            .timer {
                margin-top: -7rem;
                margin-right: $padding-desktop;
            }
        }
    }
}