.steps {
    list-style: none;
    padding: $padding-mobile $padding-mobile $padding-tablet;
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
        position: relative;
        text-align: center;
    }

    .step {
        width: 5.5rem;
        height: 10rem; // flex: 2;
        a {
            color: inherit;
        }

        &:before {
            content: '';
            @extend %align-all;
            width: 100%;
            height: 80%;
            border-width: 6px;
            border-style: solid;
        }

        .icon {
            width: 50%;
            height: auto;
            @extend %align-all;
        }

        .h2 {
            white-space: nowrap;
            @extend %align-h;
            top: 62%;
            font-size: 1.8rem;
            white-space: nowrap;
        }

        .step-description {
            @extend %align-h;
            top: 65%;
            margin-top: 4rem;
            line-height: 1;
        }
    }

    .step-icon {
        .icon {
            width: 2rem;
        }
    }
}

.step-complete {

    .icon {
        fill: $step-complete-icon;
    }

    &:before {
        border-color: $step-complete-frame;
    }

    .h2 {
        color: $step-complete-title;
    }

    .step-description {
        color: $step-complete-description;
    }

    &:after {
        content: '';
        position: absolute;
        right: -1.5rem;
        top: -1rem;
        width: 3.4rem;
        height: 3.4rem;
        border: 5px solid #fff;
        border-radius: 50%;
        background-color: $step-complete-title;
        background-image: url(../images/icons/ico-check.svg);
        background-size: 10px auto;
        background-repeat: no-repeat;
        background-position: center;
    }

    +.step-icon {
        .icon {
            fill: $step-complete-frame;
        }
    }
}

.step-active {

    .icon {
        fill: $step-active-icon;
    }

    &:before {
        border-color: $step-active-frame;
    }

    .h2 {
        color: $step-active-title;
    }

    .step-description {
        color: $step-active-description;
    }
}

.step-disabled {

    .icon {
        fill: $step-disabled-icon;
    }

    &:before {
        border-color: $step-disabled-frame;
    }

    .h2 {
        color: $step-disabled-title;
    }

    .step-description {
        opacity: 0;
    }
}

@media (max-width: $mobile-sm) {
    .steps {
        .step {
            width: 4rem;
            height: 8rem;

            h2 {
                top: 60%;
            }
        }
    }
}

@media (min-width: $tablet-min) {
    .steps {
        // padding: $padding-tablet $padding-tablet $padding-desktop;
        .step {
            width: 8rem;
            height: 14rem;

            &:before {
                border-width: 8px;
            }

            &:after {
                top: 0;
            }

            .step-description {
                margin-top: 6rem;
            }

            .h2 {
                font-size: 3rem;
                top: 58%;
            }
        }

        .step-icon {
            .icon {
                width: 3rem;
            }
        }
    }
}

@media (min-width: $desktop-lg) {
    .steps {
        padding: $padding-desktop $padding-desktop $padding-desktop;

        .step {
            width: 10rem;
            height: 17rem;

            &:before {
                border-width: 10px;
            }

            .step-description {
                margin-top: 9rem;
            }

            .h2 {
                font-size: 5rem;
                top: 43%;
            }
        }

        .step-icon {
            .icon {
                width: 3.8rem;
            }
        }
    }

    .step-complete {

        &:after {
            border-width: 8px;
            right: -2rem;
            width: 4.6rem;
            height: 4.6rem;
        }
    }
}