.registration {

    &.box-dark-left {
        &:before {
            top: 0;
            right: 0;
        }
    }
    .col-content {
        padding: $padding-mobile;
    }

    .checkbox-styled {
        label {
            line-height: 1.4;
            width: auto;
        }
    }

    p, .p {
        font-size: 1.4rem;

        &.small {
            font-size: 1.2rem;
            line-height: 1.2;
        }
    }

    @media (min-width: $tablet-min) {
        .col-content {
            padding: $padding-tablet;
        }
    }

    @media (min-width: $desktop-min) {
        .col-content {
            padding: $padding-desktop;
        }
    }
}