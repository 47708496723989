.tabs {
    font-size: 1.2rem;

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    li {
        display: inline-block;
        background-color: $box-bg;
        line-height: 1;
        margin-right: 3px;
        flex: 1;
        width: 100%;
        text-align: center;

        a {
            display: block;
            color: $breadcrumb-link;
            text-transform: uppercase;
            text-decoration: none !important;
            padding: $padding-mobile 0;
            white-space: nowrap;

            &:hover, &:active {
                color: $breadcrumb-link;
            }
        }

        &.active {
            background-color: $accent;
            a {
                color: #fff;

                &:hover, &:active {
                    color: #fff;
                }
            }
        }
    }
}

.tab-content {
    background-color: $box-bg;
    padding: $padding-mobile;
}

.tab-inner-content {
    max-width: 600px;

    ul {
        @extend %list-bullets;
    }
}

@media (min-width: $mobile-sm) {
    .tabs {
        ul {
            flex-direction: row;

            li {
                max-width: 140px;
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;
            }
        }
    }
}

@media (min-width: $tablet-min) {
    .tab-content {
        padding: $padding-tablet;
    }
}


@media (min-width: $desktop-min) {
    .tab-content {
        padding: $padding-desktop;
    }
}