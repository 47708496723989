.box {
    h2 {
        position: relative;
        margin-top: 0;
        z-index: 5;

        span {
            position: relative;
            display: block;
            padding-left: $padding-mobile;
        }

        &:before {
            content: '';
            height: 1.5rem;
            background: white;
            position: absolute;
            z-index: 0;
            top: 0;
            right: 0;
            left: 0;
        }
    }

    .col {
        min-height: $box-mobile-height;
    }

    .col-image {
        pointer-events: none;
        >div {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 5;
        }
    }

    h2 {
        margin-bottom: 0;
    }
}

.box-dark {
    position: relative;
}

.col-content {
    padding: 0 $padding-mobile $padding-mobile;
}

.box-dark,
.box-dark-right,
.box-dark-left {
    // .col-content {
    //     padding: 0 $padding-mobile $padding-mobile;
    // }
    &.box-no-header {
        .col-content {
            padding-top: $padding-mobile;
        }
    }
}

.box-dark,
.box-dark-right,
.box-dark-left {
    &:before {
        content: '';
        background-color: $box-bg;
        position: absolute;
        top: 2rem;
        right: $padding-mobile;
        left: $padding-mobile;
        bottom: 50%;
        z-index: -1;
    }

    &.box-no-header {
        &:before {
            top: 0;
        }

        h3 {
            margin-top: 0;
        }
    }
}

.col-content {
    ul {
        &:not(.quiz-score-list) {
            @extend %list-bullets;
        }
    }
}

.box-points {
    @extend %heading;
    border: 6px solid $accent;
    font-size: 8rem;
    line-height: 1.2;
    width: 90vw;
    max-width: 220px;
    margin: $padding-tablet 0 0 $padding-tablet;
    position: relative;
    z-index: 10;
    span {
        margin-left: -$padding-tablet;
    }
}

@media (min-width: $tablet-min) {
    .box {
        .col {
            min-height: $box-tablet-height;
        }
        h2 {
            span {
                padding-left: $padding-tablet;
            }

            &:before {
                height: 3rem;
            }
        }
    }

    .col-content {
        padding: 0 $padding-tablet $padding-tablet;
    }

    .box-dark,
    .box-dark-right,
    .box-dark-left {

        // .col-content {
        //     padding: 0 $padding-tablet $padding-tablet;
        // }
        &.box-no-header {
            .col-content {
                padding-top: $padding-tablet;
            }
        }
    }
}

@media (max-width: $tablet-max) {
    .box {
        .col {
            position: relative;
        }
        .col-image {
            padding: $padding-tablet 0;
            >div {
                min-height: $box-mobile-height;
            }
        }
    }
    .box-dark,
    .box-dark-right,
    .box-dark-left {

        .col-content {
            background-color: $box-bg;
        }

        h2 {
            background-color: $box-bg;
            padding-bottom: 1.8rem;
        }

        h3 {
            margin-top: 0;
        }

        &:before {
            display: none;
        }
    }
    .box-points {
        position: absolute;
        top: 0;
        left: 0;
    }
    @for $i from 1 to 10 {
        .#{col-image}-#{$i*10} {
            width: 100%;
            height: $box-mobile-height;
        }
    }
}

@media (min-width: $desktop-min) {
    .box {
        // h2 {
        //     &:before {
        //         height: 4rem;
        //     }
        // }
        // &:not(.box-heading-right) {
        //     h2 {
        //         span {
        //             padding-left: $padding-desktop;
        //         }
        //     }
        // }
        &.box-heading-right {
            h2 {
                span {
                    padding-left: 0;
                    padding-right: $padding-tablet;
                }
            }
        }
        .box-columns {
            display: flex;
        }

        .col {
            width: 50%;
            min-height: $box-desktop-height;
        }

        .col-image {
            position: absolute;
            width: 50%;
            top: 0;
            bottom: 0;
            >div {
                position: absolute;
                background-size: cover;
            }
            .col-image-product-1 {
                background-size: auto 400px;
            }
            .col-image-cl {
                background-position: center left;
            }
            .col-image-cr {
                background-position: center right;
            }
            .col-image-tl {
                background-position: top left;
            }
            .col-image-tr {
                background-position: top right;
            }
            .col-image-bl {
                background-position: bottom left;
            }
            .col-image-br {
                background-position: bottom right;
            }
        }
        .box-heading-from-center {
            h2 {
                text-align: left;
            }
        }
        &.box-heading-right {
            h2 {
                text-align: right;
            }
        }
        &.box-image-inside {
            position: relative;
            .col-image {
                >div {
                    background-size: contain;
                }

                .col-image-padded {
                    background-size: auto 350px;
                }

                .col-image-padded-lg {
                    background-size: auto 450px;
                }
            }
        }
    }

    .box-dark {
        background-color: $box-bg;
    } // .col-content {
    //     padding: 0 $padding-desktop $padding-desktop;
    // }
    .box-dark,
    .box-dark-right,
    .box-dark-left {
        // .col-content {
        //     padding: 0 $padding-desktop $padding-desktop;
        // }
        &.box-no-header {
            .col-content {
                padding-top: 0;
            }

            .box-columns {
                padding-top: $padding-desktop;
            }
        }

        &:before {
            bottom: 0;
        }
    }
    .box-dark-left {
        &:before {
            left: 0;
            right: 50%;
        }
    }
    .box-dark-right {
        &:before {
            left: 50%;
            right: 0;
        }
    }
    .box-columns {
        display: flex;
    }
    .box-image-left {
        &:not(.box-heading-right) {
            h2 {
                padding-left: 50%;
            }
        }
        .box-columns {
            flex-direction: row-reverse;
        }
        .col-image {
            right: 50%;
        }
        @for $i from 1 to 10 {
            .#{col-image-offset}-#{$i*10} {
                margin-right: -#{$i*10*1%};
            }
        }
    }
    .box-image-right {
        .col-image {
            left: 50%;
            .col-image-100 {
                background-position: center right;
            }
        }
        @for $i from 1 to 10 {
            .#{col-image-offset}-#{$i*10} {
                margin-left: -#{$i*10*1%};
            }
        }
    }
    .box-image-inside {
        .col-image {
            img {
                margin-left: -160px;
            }
        }
    }
    .box-points {
        font-size: 12rem;
        max-width: 400px;
        border-width: 10px;
        span {
            margin-left: -$padding-desktop;
        }
    } //.col-image-{percentage value} - image width
    @for $i from 1 to 10 {
        .#{col-image}-#{$i*10} {
            width: #{$i*10*1%};
            height: 50%;
        }
    }
}


@media (min-width: $desktop-lg) {
    .box {
        h2 {
            &:before {
                height: 4rem;
            }
        }

        &:not(.box-heading-right) {
            h2 {
                span {
                    padding-left: $padding-desktop;
                }
            }
        }

        &.box-heading-right {
            h2 {
                span {
                    padding-left: 0;
                    padding-right: $padding-desktop;
                }
            }
        }
    }

    .col-content {
        padding: 0 $padding-desktop $padding-desktop;
    }
}


@media (min-width: $desktop-lg) {
    .box {
        .col-image {
            .col-image-product-1 {
                background-size: auto 420px;
            }
        }
    }
}