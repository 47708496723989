.modal-container {
    background-color: #fff;
    width: 500px;
    max-width: 90vw;
    padding: $padding-mobile;
    margin: auto;
    position: relative;

    a {
        @extend %link-content;
    }

    ul {
        padding: 0;
    }
}

.modal-icon {
    @extend %align-h;
    @extend %sticker;
    top: -3rem;
}

.modal-login {
    overflow: hidden;

    .h2 {
        text-align: center;
    }

    .form-links {
        font-size: $font-size-sm;
        font-weight: 700;
        padding: 2rem 0;
        float: left;

        p {
            margin-top: 0;
        }
    }

    .btn {
        float: right;
        margin-top: 2.5rem;
    }
}

@media (min-width: $tablet-min) {
    .modal-container {
        padding: $padding-tablet;
    }
}

@media (min-width: $desktop-min) {
    .modal-container {
        padding: $padding-desktop;
    }
}