%align-all {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

%align-v {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

%align-h {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

%link-content {
    text-decoration: underline; //color: $accent;
    &:hover,
    &:active,
    &:focus {
        color: $accent-dark;
    }
}

%border {
    border: 6px solid $accent;
}

%heading {
    font-family: $font-2;
    font-weight: 900;
    color: $header-color;
}

%counter {
    @extend %heading;
    color: $accent;
    font-size: 1.6rem;
    padding-right: 1rem;
}

%counter-box {
    @extend %border;
    @extend %heading;
    font-size: $padding-mobile;
    background-color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: $padding-mobile;
    z-index: 50;
}

%error-list {
    font-size: 1.4rem;
    color: $error;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1.4;
}

.visible-desktop {
    @media (max-width: $tablet-max) {
        display: none !important;
    }
}

.visible-mobile {
    @media (min-width: $desktop-min) {
        display: none !important;
    }
}

.hidden {
    display: none !important;
}

.success {
    color: $success;
}

.error {
    color: $error;
}

%underline {
    span {
        position: relative;
        &::after {
            content: '';
            height: 0;
            border-bottom: 2px solid $accent;
            position: absolute;
            right: 1rem;
            left: 1rem;
            bottom: -5px;
            transform: translateY(-5px) scaleX(0);
            transition: all .15s linear;
        }
    }
    &.active {
        span {
            &::after {
                transform: translateY(0) scaleX(1);
            }
        }
    }
}

%menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
}


%list-bullets {
    list-style: none;
    margin: $padding-mobile 0;
    padding: 0;

    li {
        position: relative;
        padding-left: $padding-mobile;
        margin-bottom: 0;

        &:before {
            content: '';
            width: 8px;
            height: 8px;
            position: absolute;
            top: 10px;
            left: 0;
            border-radius: 50%;
            border: 2px solid $accent;
        }

        &:not(last-child) {
            margin-bottom: .5rem;
        }
    }
}

%sticker {
    height: 6rem;
    width: 6rem;
    background-color: $accent;
    border: 6px solid #fff;
    border-radius: 50%;

    .icon {
        width: 34%;
        height: auto;
        max-height: 40%;
        fill: #fff;
        @extend %align-all;
    }
}

%sticker-lg {
    @extend %sticker;
    height: 10rem;
    width: 10rem;
    border-width: 1rem;
}

%frame {
    position: relative;
    display: block;

    &:before {
        position: absolute;
        top: -2rem;
        left: -2rem;
        right: 2rem;
        bottom: 2rem;
        border: 1rem solid $accent;
        z-index: 0;
    }
}

@media (min-width: $tablet-min) {
    %border {
        border-width: 8px;
    }

    %counter-box {
        font-size: 3rem;
        padding: 3rem;
    }
}

@media (min-width: $desktop-min) {
    %underline {
        span {
            &::after {
                bottom: -10px;
            }
        }
        &:hover {
            span {
                &::after {
                    transform: translateY(0) scaleX(1);
                }
            }
        }
    }
    %frame {
        &::before {
            content: '';
        }
    }

    %border {
        border-width: 10px;
    }
}
