.cookies-message-container {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	transition: transform .3s linear;
	font-size: .8em;
	padding: $padding-mobile;
	background-color: #fff;
	box-shadow: 0 0 1rem rgba(#000, .3);
	z-index: 10;
	p {
		margin-top: 0;
	}
}

.cookies-container {
	text-align: center;
}

.cookies-accepted {
	transform: translateY(100%);
}
