.nano {
    height: 500px;
    position: relative;
}

.nano>.nano-content {
    padding: 0 $padding-mobile;
    bottom: $padding-mobile;
    margin-top: 1rem;
}

.nano>.nano-pane {
    background: rgba(#000, .1);
    top: $padding-mobile;
    bottom: $padding-mobile;
    right: $padding-mobile/2;
    overflow: hidden;
    width: 6px;
}

.nano>.nano-pane>.nano-slider {
    background-color: $accent;
    cursor: pointer;
    margin: 0;
    border-radius: 8px;
}

.nano>.nano-content:focus {
    outline: 0;
}

@media (min-width: $tablet-min) {
    .nano>.nano-content {
        padding: 0 $padding-tablet;
        bottom: $padding-tablet;
    }
    .nano>.nano-pane {
        bottom: $padding-tablet;
        top: $padding-tablet;
        right: $padding-tablet/2;
    }
}

@media (min-width: $desktop-min) {
    .nano>.nano-content {
        padding: 0 $padding-desktop;
        bottom: $padding-desktop;
    }
    .nano>.nano-pane {
        bottom: $padding-desktop;
        top: $padding-desktop;
        right: $padding-desktop/2;
    }
}
