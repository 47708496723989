footer {
	font-size: 1.2rem;
}

.footer-main {
	background-color: $box-bg;
	padding: $padding-mobile 0;
	.container {
		height: 100%;
	}
}

.footer-menu {
	@extend %menu-list;
	font-family: $font-2;
	a {
		color: inherit;
		display: block;
		padding: .5rem;
		&:hover {
			color: $accent;
		}
	}
	.active {
		a {
			color: $accent;
		}
	}
}

.footer-copy {
	background-color: #fff;
	.container {
		min-height: 60px;
	}
}

@media (max-width: $tablet-min) {
	.footer-main {
		text-align: center;
	}
}

@media (min-width: $tablet-min) {
	.footer-copy {
		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.footer-main {
		height: 80px;
	}
	.footer-menu {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 100%;
		a {
			padding: 0 2rem;
		}
		li {
			&:first-child {
				a {
					padding-left: 0;
				}
			}
		}
	}
}

@media (min-width: $desktop-min) {}
