.flash {
    background: $success;
    color: #fff;
    padding: $padding-mobile 0;

    &.error {
        background-color: $error;
    }

    .container {
        position: relative;
    }

    .close {
        position: absolute;
        right: 2rem;
        top: -.7rem;
        color: #fff;
        font-size: 4rem;
        line-height: 1;
    }
}

.tippy-tooltip {
    &.pharmpedia-theme {
        background-color: $accent;
        color: white;
        max-width: 200px;
        padding: 1.5rem;

        [x-arrow].arrow-big {
            border-top-color: $accent;
        }
    }
}