.icon {
    max-height: 100%;
    max-width: 100%;
    fill: $fill-dark;
    width: $icon-size;
    height: $icon-size;
}

.icon-light {
    fill: #fff;
}

.icon-accent {
    fill: $accent;
}

.icon-accent-dark {
    fill: $accent-dark;
}

// Icon types
.icon-logo {
    height: 100%;
    width: auto;
}

.icon-gear {
    height: 1.5rem;
    margin-bottom: -2px;
    margin-right: 1rem;
}

.icon-dropdown {
    display: inline-block;
    vertical-align: middle;
    margin-left: 1rem;
    width: 8px;
}

.icon-breadcrumbs {
    @extend %align-v;
    right: 0;
}

.icon-login {
    width: 15px;
    margin-bottom: -3px;
    margin-right: 3px;
}

.icon-timer {
    width: 55px;
    height: 55px;
}

.icon-arrow {
    height: 4rem;
    width: 3rem;
}
