.game-summary {
	.quiz-results-table {
		 thead {
			 background-color: $box-bg;
		 }
	}

	.tab-inner-content {
		max-width: 100%;
	}

	.nano {
			> .nano-content {
				padding: 0;
			}

			> .nano-pane {
				right: 0;
			}
		}
}
