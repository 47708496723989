.section {
    position: relative;
    padding: $padding-mobile 0;
    a:not(.btn) {
        @extend %link-content;
    }
}

.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 $padding-mobile;
}

@media (max-width: $mobile-max) {
    .container {
        &.mobile-full {
            padding: 0;
        }
    }
}

@media (min-width: $tablet-min) {

    .container {
        padding: 0 $padding-tablet;
    }

    .section {
        padding: $padding-tablet 0;
    }
}

@media (min-width: $desktop-min) {

    .container {
        width: 100%;
        max-width: 1210px;
    } // .section {
    //     padding: 9rem 0 0 0;
    //     &:last-of-type {
    //         padding-bottom: 9rem;
    //     }
    // }
    .section-test-failed {
        .box {
            h2 {
                max-width: 60%;
            }

            .col {
                min-height: 300px;
            }
        }
    }
}

@media (min-width: $desktop-lg) {
    .section {
        padding: 9rem 0 0 0;

        &:last-of-type {
            padding-bottom: 9rem;
        }
    }
}