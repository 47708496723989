.game {
    max-width: 100%;
    background-color: $box-bg;
    margin: 0 auto;

    .points-value {
        @extend %heading;
        font-size: 6rem;
        line-height: 1;
    }

    .game-sidebar {
        background-color: $box-bg;
        margin-left: 0;

        .game-points {
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 0;
        }

        .points-label {
            margin-right: 0;
            font-family: $font-2;
            font-size: 1.6rem;
            font-weight: 900;
        }
    }

    .game-timer {
        width: 100%;
        text-align: center;
        border: 0 none;
    }

    .timer {
        width: 310px;
    }
}

.game-sidebar {
    width: calc(100% - 805px);
}

.game-board, .game-title {
    background-color: $box-bg;
}

.board {
    .board-element {
        border: 2px solid $form-border;
    }
}

.game-modal {
    .buttons {
        position: static;
        display: flex;

        .btn {
            min-width: 200px;
        }
    }

    .game-points {
        color: $accent-dark;
    }

    .modal-close {
        top: 15px;
        right: 22px;
    }

    .h2 {
        text-align: center;
        margin-top: 0;
    }
}

.game-modal {
    &.modal-container {
        padding: 0;
    }
}

@media (min-width: $desktop-min) {
    .game {
        .game-timer {
            .timer {
                margin-top: -3rem;
            }
        }
    }
}

@media (min-width: $desktop-lg) {

    .game {

        .board {
            margin-top: $padding-tablet;
            margin-left: -$padding-tablet;
            margin-bottom: -$padding-tablet;
            background-color: #fff;
            z-index: 2;
            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 2rem;
                right: 2rem;
                border: 1rem solid $breadcrumb-link;
                background-color: $box-bg;
                z-index: -1;
            }

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                background-color: white;
                z-index: -1;
            }
        }

        .game-points {
            margin-top: $padding-tablet;
        }
    }
}

@media (max-width: $desktop-lg) {
    .game {
        padding-bottom: $padding-mobile;
    }
    .game-timer {
        margin-top: 3rem;
    }
}

@media (max-width: 955px) {
    .game {
        .game-info {
            display: block;
            color: $accent;
        }

        .game-board {
            display: none;
        }

        .game-sidebar {
            display: none;
        }
    }
}