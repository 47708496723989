.static-content {
    .container {
        background-color: $box-bg;
        padding-top: 2rem;
        overflow: hidden;
    }

    .drug-info {
        padding-bottom: 0;
    }

    .article-content {
        flex: 2;
        ul {
            @extend %list-bullets;
        }
    }

    h2, .h2 {
        font-size: 1.8rem;
    }

    h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        line-height: 1.4;
        margin-bottom: .5em;
        margin-top: 0;
    }
}

.news-list {
    display: none;
}

.news-link {
    span {
        &:after {
            width: 0;
        }
    }
}

.news-dropdown {
    .icon {
        transition: transform .15s linear;
    }

    &.dropdown-open {
        .icon {
            transform: rotate(180deg);
            fill: $accent;
        }

        >a {
            color: $accent;
        }
    }
}

.article-links {
    display: none;
}


@media (max-width: $tablet-max) {
    .news-link {
        line-height: 1.4;
        font-size: 1.6rem;

        .icon-dropdown {
            display: none;
        }
    }

    .news-list {
        max-width: 500px;
    }

    .article-links {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 5rem;
        text-transform: uppercase;
        font-family: $font-2;
        font-weight: 900;
        font-size: 1.2rem;

        .icon {
            fill: $accent;
            margin: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .article-links-next, .article-links-prev {
            text-decoration: none;
            max-width: 45%;
            position: relative;
        }
    }

    .article-links-prev {
        padding-left: 2rem;
        .icon {
            left: 0;
        }
    }

    .article-links-next {
        padding-right: 2rem;
        text-align: right;
        .icon {
            right: 0;
        }
    }
}

@media (min-width: $tablet-min) {
    .static-content {
        .container {
            padding: $padding-tablet;
        }

        h2, .h2 {
            font-size: 2rem;
        }
    }
}


@media (min-width: $desktop-min) {
    .static-content {
        .container {
            padding: $padding-desktop;
        }

        h2, .h2 {
            font-size: 2.4rem;
        }
    }

    .news-container {
        display: flex;

        .news-list {
            display: block;
        }
    }

    .main-header {
        .news-link {
            display: none;
        }
    }

    .news-dropdown {
        .icon {
            display: none;
        }
    }

    .news-list {
        flex: 1;
        padding: 0 $padding-desktop 0 0;
        text-transform: uppercase;
        font-family: $font-2;
        font-weight: 900;
        font-size: 1.2rem;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            overflow: hidden;

            .news-link {
                margin-bottom: 2rem;

                a {
                    display: block;
                    text-decoration: none;
                    position: relative;
                    margin-left: -3rem;
                    padding-left: 3rem;
                    transition: .2s;
                    max-width: 100%;
                    color: $body-color;

                    .icon-dropdown {
                        fill: $accent;
                        transform: translateY(-50%) rotate(-90deg);
                        position: absolute;
                        width: 2rem;
                        top: 50%;
                        left: 0;
                        margin: 0;
                    }
                }

                &:hover, &.active {
                    a {
                        margin-left: 0;
                        color: $accent;
                    }
                }
            }
        }
    }
}